import React, { useEffect, useState } from "react";
import "./styles.css";
import { Link} from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
const MainNav = () => {
  const  [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
      setNavOpen(false);
  },[]);

  const NavDisplay = () => {
    return (
      <>
        <nav className="mainNavCont">
          <div className="mainNavClose" onClick={() => {
            closeNav();
          }}>
            <AiOutlineClose size={50} />
          </div>
          <ul className="mainNav">
            <li>
              <Link to={"/"} onClick={() => {
            closeNav();
          }}>Inicio</Link>
            </li>
            <li>
              <Link to={"/tours"} onClick={() => {
            closeNav();
          }}>Viajes</Link>
            </li>
            <li>
              <Link to={"/excursions"} onClick={() => {
            closeNav();
          }}>Excursiones</Link>
            </li>
            <li>
              <Link to={"/about"} onClick={() => {
            closeNav();
          }}>Sobre nosotros</Link>
            </li>
            <li>
              <Link to={"/contact"} onClick={() => {
            closeNav();
          }}>Contacto</Link>
            </li>
            <li>
              <a href={"https://indochinanaturally.com"}>
                Indochina Naturally
              </a>
            </li>
          </ul>

          
        </nav>
        <div className="clearfix"></div>
        
      </>
    );
  };

  const openNav = () => {
    setNavOpen(true);
  }

  const closeNav = () => {
    setNavOpen(false);
  }

  return (
    <>
    <div className="logoContainer">
      <Link to={'/'}><img src={'https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2FFull-gold-small.jpg?alt=media&token=a9f0f073-b0a1-4da6-b8d0-8fd39449ed90'} alt="Guia Lanka Tours"/></Link>
      
    </div>
      <div className="mainNavOpen" onClick={()=>{ openNav(); }}>
        <RxHamburgerMenu size={50} />
      </div>

      {navOpen ? <NavDisplay /> : ""}
    </>
  );
};

export default MainNav;
