import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { tourDetails } from "../../../constants/groupTourConstant";
import SingleTour from "./components/SingleTour";
import ConntactSection from '../HomePage/components/ContactSection';
const GroupTours = () => {
    const [singleTour, setSingleTour] = useState("");
  let { id } = useParams();
    var tourArr = useMemo(() => [], []);
    tourArr = tourDetails;
  useEffect(() => {
    tourArr.forEach(tour => {
        if(tour.slug === id){
            setSingleTour(tour);
        }
    });
  }, [id, tourArr]);
    return(
        <>
        <SingleTour title={singleTour.title} slides={singleTour.slides} description={singleTour.description}/>
        <ConntactSection />
        </>
    );
}

export default GroupTours;