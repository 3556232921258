import React from "react";
import VideoWrapper from "../../../common/videoWrapper/VideoWrapper";
// import { Link } from "react-router-dom";

const VideoSection = ({ url }) => {
  return (
    <>
      <section className="mainSection" id="home">
        <VideoWrapper url={url} />
        {/* <div className="home-image-cont">
          <img src="https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fthumb.jpg?alt=media&token=03a54a57-1220-4d5d-aace-93b7f202b010&_gl=1*v26lh*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM1NzgwNy40LjEuMTY5OTM1NzgzOS4yOC4wLjA." alt="" />
          <h2 className="h2 h2-main color-white text-center padding-bottom-50"><span className="big-text">Special Offer</span><br/>Maldives Scuba Diving & Sri Lanka Active Holiday.
          <br/> <span className="small-text"><Link className="color-white" to={'/offers/maldives_scuba_diving_sri_lanka_active_holiday#bookingForm'}>Get your offer now</Link></span></h2>
        </div> */}
      </section>
    </>
  );
};

export default VideoSection;
