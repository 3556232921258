export const offerDetails = [
        {
            title: "Maldives Scuba Diving & Sri Lanka Active Holiday ",
            slug: "maldives_scuba_diving_sri_lanka_active_holiday",
            summary:"This fascinating active holiday includes the best of both worlds: Scuba diving & snorkeling in the Maldives combined with a Sri Lanka Tour.Ten days and nine nights active holiday in which three nights at the Maldives Island of Rasdhoo and six nights in Sri Lanka.",
            inclues:"- Activities and attractions as per the itinerary.<br/>- A total of nine nights of accommodation on a Twin-Sharing basis with breakfast.<br/>- Return Transfers by speedboat in the Maldives.<br/>- Sri Lanka with transport & English-speaking guide.",
            excludes:"-flights, visas, travel insurance.<br/>-lunch & dinner.<br/>-Early check-in & late check-out.<br/>-personal expenses and any items not mentioned in the above “Includes” section.",
            special:"Our fare is USD 1,590 per person on a twin-sharing basis, for a minimum of two personas.For a group of four or more persons, the fare is USD 1,490 per person on a twin sharing basis.<br/>The single supplement is USD 350<br/>Reservations require USD 100 initial fee and the balance 30 days before arrival.",
            descriptions:
              [
                {
                    img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fday1.jpg?alt=media&token=8fdc5bad-65c8-4596-9e2d-068e0378ccd2&_gl=1*1weooot*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM0Njg0Ny4zLjEuMTY5OTM0Nzg3OS41Ni4wLjA.",
                    desc:"<h3>Day 1</h3><p>Arrival at the Maldives International Airport (MLE).<br/>Transfer to the Rasdhoo island by speedboat.<br/>Excursion to the sandbank.<br/>Night: Rasdhoo island.</p>"
                },
                {
                    img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fday2.jpg?alt=media&token=ac45d72e-6de1-42fe-b886-1e185aaf17be&_gl=1*nvzg7b*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM0Njg0Ny4zLjEuMTY5OTM0NzkwMi4zMy4wLjA.",
                    desc:"<h3>Day 2</h3><p>Breakfast<br/>Departure in the diving boat.<br/>Two standard dives OR one beginner’s dive.<br/>Sunset fishing & barbecue.<br/>Night: Rasdhoo Island.</p>"
                },
                {
                    img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fday3.jpg?alt=media&token=9e5a9c3c-7d0a-43d7-80c1-8c5cb84b5aa9&_gl=1*j1sff*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM0Njg0Ny4zLjEuMTY5OTM0NzkxNi4xOS4wLjA.",
                    desc:"<h3>Day 3</h3><p>Breakfast.<br/>Travel to the Manta Point by speed boat.<br/>Snorkeling for mantas including equipment.<br/>Night: Rahdhoo island</p>"
                },
                {
                    img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fday4.jpg?alt=media&token=f30927d3-4d98-47d9-9493-094e9931a2a5&_gl=1*4fc40a*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM0Njg0Ny4zLjEuMTY5OTM0NzkzNi42MC4wLjA.",
                    desc:"<h3>Day 4</h3><p>Breakfast.<br/>Free morning until the departure time by speedboat to The Maldives International Airport (MLE).<br/>Arrival at the Colombo International Airport, Sri Lanka (CMB).<br/>Transfer to the hotel at Negombo.<br/>Night: Negombo.</p>"
                },
                {
                    img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fday5.jpg?alt=media&token=3251aca7-5686-4626-9299-a92bd78aed10&_gl=1*m070vv*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM0Njg0Ny4zLjEuMTY5OTM0Nzk1OS4zNy4wLjA.",
                    desc:"<h3>Day 5</h3><p>Breakfast.<br/>Dambulla Cave Temple<br/>Jeep safari at Minneriya National Park<br/>NIght: Sigiriya</p>"
                },
                {
                    img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fday6.jpg?alt=media&token=2fc1e365-c879-4100-9438-e72ac3fb2775&_gl=1*1bhd69p*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM0Njg0Ny4zLjEuMTY5OTM0Njk3NS42MC4wLjA.",
                    desc:"<h3>Day 6</h3><p>Breakfast<br/>Sigiriya Rock Fortress<br/>Excursion by rowboat to a traditional village experience<br/>Hindu temple at Matale<br/>Kandy lake and street markets<br/>Night: Kandy</p>"
                },
                {
                    img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fday7.jpg?alt=media&token=3dc03ccb-2cec-4cd4-86df-4373f3afb19e&_gl=1*wbn418*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM0Njg0Ny4zLjEuMTY5OTM0ODAwNi42MC4wLjA.",
                    desc:"<h3>Day 7</h3><p>Breakfast<br/>Sri Dalada Maligawa (Temple of the Sacred Relic).<br/>Ramboda falls.<br/>Tea fields & Nuwara Eliya.<br/>Scenic train journey to Ella.<br/>Night: Ella</p>"
                },
                {
                    img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fday8.jpg?alt=media&token=bd20cb6d-421e-4539-91d7-40ea556b1155&_gl=1*4rnqp3*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM0Njg0Ny4zLjEuMTY5OTM0ODAyMy40My4wLjA.",
                    desc:"<h3>Day 8</h3><p>Breakfast<br/>Trekking to Ella Rock & Nine arch bridge<br/>Freetime in Ella<br/>Night: Ella</p>"
                },
                {
                    img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fday9.jpg?alt=media&token=e981a073-b58b-4b65-9fc8-591c2fb0a454&_gl=1*dkstfb*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM0Njg0Ny4zLjEuMTY5OTM0ODA2Mi40LjAuMA.",
                    desc:"<h3>Day 9</h3><p>Breakfast<br/>South Coast & fishing boats<br/>Historic Dutch Galle Fort<br/>Transfer to Negombo<br/>Night: Negombo</p>"
                },
              ],
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Foffers%2Fthumb.jpg?alt=media&token=03a54a57-1220-4d5d-aace-93b7f202b010&_gl=1*v26lh*_ga*MTcyOTE4MzUxNi4xNjk5MjYyNzEy*_ga_CW55HF8NVT*MTY5OTM1NzgwNy40LjEuMTY5OTM1NzgzOS4yOC4wLjA."
          },
]