import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { excursionDetails } from "../../../constants/excursionsConstant.js";
import SingleTour from "./components/SingleTour";
import ConntactSection from '../HomePage/components/ContactSection';
const ExcursionsView = () => {
    const [singleTour, setSingleTour] = useState("");
  let { id } = useParams();
    var tourArr = useMemo(() => [], []);
    tourArr = excursionDetails;
  useEffect(() => {
    tourArr.forEach(tour => {
        if(tour.slug === id){
            setSingleTour(tour);
        }
    });
  }, [id, tourArr]);

  useEffect(() => {
   
  }, []);
    return(
        <>
        {singleTour? <SingleTour title={singleTour.title} slides={singleTour.slides} description={singleTour.description}/> : ""}
        <ConntactSection />
        </>
    );
}

export default ExcursionsView;