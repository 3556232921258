import React from 'react';
import './styles.css';

const SingleTourCard = ({imgSrc, title}) => {
    return(
        <>
        <div className="singleTourCard">
            <img src={imgSrc} alt={title} />
        </div>
        </>
    );
}

export default SingleTourCard;
