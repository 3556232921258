import React from "react";
import HomeTourCard from "../../../common/HomeTourCard/HomeTourCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Col, Button} from "react-bootstrap";
import "./styles.css";

const TourSection = ({ tours }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 465 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  var items = [];
  items = tours;

  return (
    <>
      <section className="mainSection toursSection bg-dark-green">
        <Container fluid>
        <Row className="flex-row-reverse">
            <Col md={6}>
              <h2 className="h2 h2-main color-white text-left padding-over-50 padding-bottom-50">
              VIAJE DE AVENTURA CON GRUPO ABIERTO <span className="color-yellow">EN ESPAÑOL</span>
              </h2>
              <p className="color-white">¡Prepárate para un viaje de aventuras en Sri Lanka que nunca olvidarás!</p>
              <p className="color-white">Combina historia antigua, vida local en pueblos rurales, fascinantes rutas de senderismo por las Tierras Altas, vida salvaje única en safaris y playas paradisíacas.</p>
              <p className="color-white">Vive una diversidad de experiencias que te acompañarán para siempre.</p>
              <a href="/group-tours/sri-lanka-group-open-tour"><Button className="bg-yellow button-rounded color-green" size="lg" >Vista</Button></a>
            </Col>
            <Col md={6}>
            <div className="aboutImageContainer padding-over-50 ">
                    <img src={'https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Ftours%2Fgroup%2Fsri-lanka-group-tour.jpg?alt=media&token=ccbda03a-cfd6-40a8-af72-0e1bb32d544b'} alt="VIAJE DE AVENTURA CON GRUPO ABIERTO EN ESPAÑOL" />
                </div>
            </Col>
          </Row>
        </Container>
        <Container>
          
          <Row>
            <Col md={12}  id="tours">
              <h2 className="h2 h2-main color-white text-center padding-bottom-50">
                <span className="small">Descubre nuestros</span>
                <br />
                Viajes
              </h2>
            </Col>
            <Col md={12} className="top-padding-150">
              <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={false}
                customTransition="all .5s"
                transitionDuration={500}
                containerClass="carousel-container"
              >
                {items
                  ? items.map((item, i) => {
                      return <HomeTourCard key={i} data={item} />;
                    })
                  : ""}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TourSection;
