import React from "react";
import "./styles.css";
import { Container, Row, Col, Button } from "react-bootstrap";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const SingleOffer = ({ title, thumbnail, descriptions, summary, includes, excludes, special }) => {
  const DescriptionOut = ({descrips}) => {
    // console.log(descrips);
    return (
      <>
      
            <Row className="image_row">
          <Col md={3}>
            <div className="imagecont_round">
              <img
                className="image_absolute"
                src={descrips.img} alt=""
              />
            </div>
          </Col>
          <Col md={9}>
            <span dangerouslySetInnerHTML={{ __html: descrips.desc }} ></span>
          </Col>
        </Row>
        
      </>
    );
  };

  return (
    <>
      <section className="mainSection singleTourSection">
        <Container fluid>
        
          <Row >
            <Col md={9} className="padding-side-0">
              <img src={thumbnail} className="thumb_image" alt=""/>
            </Col>

            <Col
              md={3}
              className="padding-side-50 padding-over-50 content-align-center"
            >
              <h1 className="h1 h1-main text-left color-green">{title}</h1>
              <p>{summary}</p>
              
            </Col>
          </Row>
          <Container>
            <Row className="">
            
              <Col md={9} className="offer-special">
                <p className="" dangerouslySetInnerHTML={{__html:special}}></p>
                <h2 className="h2 h2-main color-green">Itinerary</h2>
                <span  className="btn_sticky"><a href="#bookingForm"><Button>Book Now</Button></a></span>
                
                <span
                className="itine-cont"
                >
                  {descriptions
                    ? descriptions.map((description) => {
                        return (<>
                        <DescriptionOut descrips={description} />
                        </>);
                      })
                    : ""}
                </span>
                <Row>
                    <Col md={6}>
                        <h2>Includes</h2>
                      <p><span dangerouslySetInnerHTML={{__html: includes}}></span></p>
                    </Col>
                    <Col md={6}>
                    <h2>Excludes</h2>
                      <p><span dangerouslySetInnerHTML={{__html: excludes}}></span></p>
                    </Col>
                </Row>
              </Col>
            </Row>
            <Row>
            <Col id="bookingForm">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeUcIJkix8EF1eKKDkxDen43tSOTZb4erba3Mssq1SxObctOg/viewform?embedded=true" width="100%" height="1200" frameborder="0" marginheight="0" marginwidth="0" title="bookingform" >Loading…</iframe>
            </Col>
          </Row>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default SingleOffer;
