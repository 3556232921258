import React from "react";
import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";

import "react-multi-carousel/lib/styles.css";
import SingleTourCard from "../../../common/SingleTourCard/SingleTourCard";
import ExcursionsSlider from "./ExcursionsSlider";

const SingleTour = ({ title, slides, description }) => {
  console.log("slides", slides);
  
  return (
    <>
      <section className="mainSection singleTourSection">
        <Container fluid>
          <Row>
            <Col md={9} className="padding-side-0">
              {/* <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={false}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
              >
                {slides ? slides.map((slide, i) => {
                    return( */}
                        <SingleTourCard imgSrc={slides[0].img} />
                    {/* )
                 }) : "" } */}

                
              {/*  </Carousel> */}
            </Col>

            <Col
              md={3}
              className="padding-side-50 padding-over-50 content-align-center"
            >
              <h1 className="h1 h1-main text-left color-green">{title}</h1>
            </Col>
            
          </Row>
          <Container>
          <Row>
            <Col md={4}>
                <span className="h2 h2-main color-green"></span>
            </Col>
          <Col md={8} className='padding-over-50'>
          <span
                                dangerouslySetInnerHTML={{ __html: description }}
                              ></span>
          </Col>
          </Row>
          <ExcursionsSlider/>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default SingleTour;
