import React from "react";
import { excursionDetails } from "../../../../constants/excursionsConstant";
import { Container, Row, Col } from "react-bootstrap";
import "./styles.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const ExcursionsSlider = () => {
  var items = [];
  items = excursionDetails;

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 465 },
      items: 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <>
      <section className="mainSection ">
        <Container fluid>
          
          <Container>
            
            <Row>
              <Col md={12} className="padding-over-50">
              <Carousel
          swipeable={false}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={false} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={false}
          customTransition="all 2s"
          transitionDuration={3000}
          containerClass="carousel-container"
        >
            {items ?
                items.map((item, i) => {
                   return(
                    <a className="card2" href={"/excursions/" + item.slug}>
                            <div
                              className="card2__background"
                              style={{
                                backgroundImage: `url(` + item.slides[0].img + `)`,
                              }}
                            ></div>
                            <div className="card2__content">
                              <p className="card2__category"></p>
                              <h3 className="card2__heading">{item.title}</h3>
                            </div>
                          </a>
                   )
                }) : ""}
          
        </Carousel>
              </Col>
            </Row>
          </Container>
        </Container>
      </section>
    </>
  );
};

export default ExcursionsSlider;
