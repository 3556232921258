import React from 'react';
import { Link } from 'react-router-dom';
import './styles.css'
const HomeTourCard = ({data}) => {
    
    return(<>
        <div className='tourCardContainer'>
            <div className='tourCard'>
                <img src={data.thumbnail} alt={data.title} />
                <div className='cardCaption'>
                <h3>
                    <Link to={'/tours/'+data.slug} className="customCursor">{data.title}</Link>
                </h3>

                </div>
            </div>
        </div>
    </>);
}

export default HomeTourCard;