import React from "react";
import './styles.css';

const VideoWrapper =({url}) => {
    return(<>
    <div className="videoWrapper">
    <video  autoPlay muted loop>
        <source src={url} type="video/mp4" width="100%" />
    </video>

</div>
    </>);
}

export default VideoWrapper;