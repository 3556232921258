export const excursionDetails = [
    {
      title: "Excursión a la ciudad de Kandy",
      slug: "kandy-city-excursion",
      description:
        "<p>La excursión a la ciudad de Kandy incluye una visita al templo Sri Dalada Maligawa, uno de los templos más sagrados para los budistas a nivel mundial, que contiene una reliquia sagrada de Gautama Buda. Esta reliquia fue regalada a Sri Lanka desde la India en el siglo IV, y el templo de Kandy se construyó a principios del siglo XVIII.</p><p>La famosa procesión Kandy Esala Perahera parte de este templo.</p>< p>El mercado municipal de Kandy está ubicado en un edificio central donde la población local compra arroz, legumbres, especias, carne, pescado, frutas y verduras.</p><p>El lago Kandy es un soplo de aire fresco para el ciudad y también un lugar con algo de vida silvestre urbana como tortugas, iguanas grandes y una variedad de aves.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fkandy.jpg?alt=media&token=7de74979-c057-4355-88c3-30e8b2ffc34f"},
      ]
    },
    {
      title: "Fortaleza de roca de Sigiriya",
      slug: "sigiriya-rock-fortress",
      description:
        "<p>La fortaleza de roca de Sigiriya, declarada Patrimonio de la Humanidad por la UNESCO, fue fundada en el siglo V. La ciudad contiene altos muros, fosos profundos, jardines, pinturas famosas y la entrada del león a la ciudadela. Representa lo mejor de la ingeniería, la arquitectura, la jardinería, la hidrología, la pintura y la escultura cingalesas antiguas. Además, las cuevas de las laderas de la fortaleza de Sigiriya fueron utilizadas por monjes budistas hace 2.200 años. Además, existe amplia evidencia arqueológica de asentamientos prehistóricos en esta zona.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fsigiriya.jpg?alt=media&token=a5ff5251-0bb1-4634-aff0-ebee005874d4"},
        {img:""}
      ],
    },
    {
      title: "Ciudad antigua de Anuradhapura",
      slug: "anuradhapura-ancient-city",
      description:
        "<p>La Ciudad Sagrada de Anuradhapura, declarada Patrimonio de la Humanidad por la UNESCO, es uno de los centros más grandes del antiguo mundo budista y fue la primera capital real del reino insular, hace 2.500 años. Al igual que las ciudades de Atenas y Roma, floreció en majestuosidad y grandeza. Los embajadores cingaleses estuvieron en la corte romana, especialmente durante el reinado de César Augusto.</p><p>Reyes, monjes, ingenieros y escultores han dejado en esta ciudad la mayor colección de monumentos budistas de la isla. Incluso hoy en día, parte de la ciudad se considera Ciudad Sagrada (puja nagaraya). Los sitios más importantes de la ciudad antigua incluyen el Árbol Sagrado Bodhi (Sri Maha Bodhi), los magníficos templos de Jethavana, Abhayagiri y Ruvanvaelisaya, la estatua del Buda Samadhi, los estanques de baño gemelos (Kuttam Pokuna), el estanque gigante (Ath Pokuna ), Isurumuni Viharaya y el Jardín Real (Ranmasu Uyana).</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fanuradhapura.jpg?alt=media&token=e864c3e6-1dc1-4113-a593-61a5ef597cf2"},
      ],
    },
    {
      title: "Templo de roca de Buduruwagala",
      slug: "buduruwagala-rock-temple",
      description:
        "<p>El templo de roca de Buduruwagala, ubicado en medio de la exuberante vegetación de la campiña de Sri Lanka, es una joya escondida del arte religioso antiguo. Este sitio remoto es famoso por sus notables grabados rupestres, que datan del siglo IX o X d.C. Maravíllate ante la imponente pared de roca adornada con figuras intrincadamente talladas, incluida una colosal estatua de Buda de pie que se eleva a más de 50 pies de altura. Alrededor de la figura principal hay representaciones más pequeñas de deidades, bodhisattvas y asistentes, cada uno de los cuales muestra una artesanía exquisita y atención al detalle. Mientras exploras Buduruwagala, quedarás cautivado por la atmósfera serena y el sentido de reverencia que rodea este sitio sagrado.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fbuduruwagala.jpg?alt=media&token=bd4f8d11-8cb5-4b0d-b88f-b43cff8c2420"},
      ],
    },
    {
      title: "Templo de la cueva de Dambulla",
      slug: "dambulla-cave-temple",
      description:
        "<p>El templo de la cueva de Dambulla, declarado Patrimonio de la Humanidad por la UNESCO, ha sido utilizado por monjes budistas desde el siglo II a.C. El primer templo fue construido en el siglo I a.C. por el rey Walagamba. El sitio contiene cinco templos construidos dentro de una cueva muy grande en diferentes épocas por varios reyes cingaleses. Juntos forman una magnífica galería de arte con estatuas y pinturas coloridas.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fdambulla.jpg?alt=media&token=d0fabed2-573c-4c02-a502-01715fc9eca8"},
      ],
    },
    {
      title: "Excursión Ella",
      slug: "ella-excursion",
      description:
        "<p>Ubicado en Ella, el Puente de los Nueve Arcos es uno de los puentes más emblemáticos de Sri Lanka. Es un puente ferroviario de piedra, construido por el Sr. Appuhami (un arquitecto local) con ingenieros británicos durante el período colonial. Está rodeado de vistas pintorescas de colinas exuberantes. Desde el mirador de 98 acres (Little Sri Padya), los visitantes pueden disfrutar de impresionantes vistas de los alrededores y Ella Gap. Los visitantes también pueden disfrutar de la primera tirolesa doble de la isla, ubicada entre los exuberantes arbustos de té cerca de la base de la colina del mirador. La tirolesa dual se extiende a lo largo de medio kilómetro, deslizándose a una velocidad máxima de 80 km/h, y ofrece una vista panorámica de los hermosos cerros de la zona.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fella.jpg?alt=media&token=b99dc92a-aef1-451d-9e0d-1335f7c2ccae"},
      ],
    },
    {
      title: "Barcos de pescadores y mercados",
      slug: "fishermen-s-boats-and-markets",
      description:
        "<p>Los barcos en los mercados pesqueros se mecen en las tranquilas aguas de la costa de Sri Lanka, y sus colores vibrantes se reflejan en la luz del sol de la mañana. Estas embarcaciones tradicionales, adornadas con diseños intrincados y redes resistentes, sirven como medio de subsistencia para las comunidades costeras, proporcionando alimento y medio de vida durante generaciones. Al comenzar el día, los pescadores se afanan alrededor de sus barcos, clasificando las capturas del día y preparándose para los bulliciosos mercados que les esperan. El aire está lleno de risas, conversaciones y el canto ocasional de un ave marina sobre nuestras cabezas. Cada barco cuenta una historia de resiliencia, perseverancia y la conexión eterna entre el hombre y el mar.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Ffishermen.jpg?alt=media&token=8d46018c-13f4-4d49-8945-f7e74e4d0e2e"},
      ],
    },
    {
      title: "Fuerte de Galle",
      slug: "galle-fort",
      description:
        "<p>La Fortaleza de Galle fue un puerto importante durante el reinado de los reyes cingaleses, ya en el siglo XII a.C. En 1505, un grupo de marineros portugueses liderados por Don Lorenzo de Almeida invadió la isla y construyó la primera fortificación en Galle en un acantilado que se adentraba en el mar. La fortaleza fue capturada por los holandeses el 13 de marzo de 1640, después de una batalla. La pequeña fortificación de Galle fue ampliada y mejorada considerablemente según su propio estilo arquitectónico y fue considerada una de las fortalezas más importantes de la Compañía Holandesa de las Indias Orientales (VOC), cuyo monograma de piedra bellamente grabado de 1669 aún es visible sobre la entrada. . La Fortaleza de Galle fue declarada Patrimonio de la Humanidad por la UNESCO.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fgalle.png?alt=media&token=12dd59b5-f54a-481a-9ca0-7061013a6701"},
      ],
    },
    {
      title: "Cascadas de Dunhinda",
      slug: "dunhinda-waterfalls",
      description:
        "<p>Las cataratas Dunhinda, ubicadas cerca de Badulla en Sri Lanka, son una de las cascadas más espectaculares del país. Con un desnivel aproximado de 64 metros, Dunhinda crea una niebla que le da nombre, derivado de 'dun' en cingalés, que significa niebla o spray. Para llegar a la cascada, los visitantes deben atravesar un sendero pintoresco a través de la jungla, lo que agrega un elemento de aventura a la visita. Rodeado de exuberante vegetación y un ambiente tranquilo, Dunhinda es un destino popular para los amantes de la naturaleza y los excursionistas que buscan disfrutar de la belleza escénica de Sri Lanka.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fdunhinda.jpg?alt=media&token=244c2acd-2b8b-4eda-ab25-1e976985868f"},
      ],
    },
    {
      title: "Antiguo monasterio budista de Mihinthale",
      slug: "mihinthale-ancient-buddhist-monastery",
      description:
        "<p>El Antiguo Monasterio de Mihintale es un sitio sagrado en Sri Lanka considerado la cuna del budismo en el país. Este lugar histórico alberga una serie de estructuras antiguas y religiosas, que incluyen estupas, santuarios y un antiguo hospital. La escalera de granito con 1.840 escalones conduce a la cima de la montaña, donde se encuentra la gran estupa de Mahaseya. Desde aquí se pueden disfrutar de vistas panorámicas del paisaje circundante. Mihintale es un destino importante para los peregrinos budistas y una joya cultural para los visitantes interesados ​​en la historia y la espiritualidad de Sri Lanka.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fmihintale.jpg?alt=media&token=8a5c172d-237f-43c3-b34f-75ff15e27d26"},
      ],
    },
    {
      title: "Safari en jeep por el Parque Nacional Minneriya",
      slug: "minneriya-national-park-jeep-safari",
      description:
        "<p>Un safari en jeep en el Parque Nacional Minneriya ofrece la oportunidad de observar una amplia variedad de vida silvestre en su hábitat natural. Famoso por la 'Reunión de Elefantes', donde cientos de elefantes se congregan alrededor del tanque Minneriya durante la estación seca, el parque también alberga leopardos, ciervos, búfalos de agua y una variedad de aves exóticas. Durante el safari, los visitantes pueden disfrutar de impresionantes paisajes que incluyen pastizales, bosques y humedales, todo desde la comodidad y seguridad de un jeep guiado.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fminneriya.jpg?alt=media&token=50322ef9-e1cc-4158-8e39-b313d68d66b0"},
      ],
    },
    {
      title: "Templo de la roca de Nelligala",
      slug: "nelligala-rock-temple",
      description:
        "<p>El Templo Nelligala, ubicado en las colinas de Kandy, Sri Lanka, es famoso por su impresionante estatua de Buda, una de las más grandes del país con aproximadamente 25 metros de altura. Además de la estatua, el templo cuenta con hermosos jardines y áreas de meditación para disfrutar de la tranquilidad del entorno. Es un lugar sagrado para los budistas locales y un destino popular para los turistas que buscan cultura y espiritualidad. Ofrece una visita fascinante que combina arte, religión y naturaleza en un entorno sereno y pacífico.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fnelligala.jpg?alt=media&token=cc81a077-4aa0-40d0-848a-e44170067478"},
      ],
    },
    {
      title: "Excursión a la ciudad de Nuwara Eliya",
      slug: "nuwara-eliya-city-excursion",
      description:
        "<p>Nuwara Eliya es una ciudad turística en las tierras altas centrales de Sri Lanka, bendecida con un clima saludable y vistas impresionantes de valles, prados, montañas y plantaciones de té. Nuwara Eliya, también conocida como 'Pequeña Inglaterra', fue el refugio favorito de los colonos británicos que intentaron recrear un típico pueblo inglés. El Grand Hotel, la oficina de correos de ladrillo rojo, el Hill Club, el campo de golf de 18 hoyos, Victoria Park y Gregory Lake son las atracciones turísticas más destacadas.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fnuwaraeliya.jpg?alt=media&token=4e38dd82-0b53-42ee-b95f-18c5a2d6d216"},
      ],
    },
    {
      title: "Ciudad antigua de Polonnaruwa",
      slug: "polonnaruwa-ancient-city",
      description:
        "<p>Polonnaruwa, declarada Patrimonio de la Humanidad por la UNESCO, fue la capital medieval de Sri Lanka entre los siglos X y XII d.C. Polonnaruwa también sirvió como una ciudad de importancia estratégica durante la era de Anuradhapura. Dentro de la ciudadela (centro de la ciudad) se encuentran el Palacio Real del Rey Parakumbhahu el Grande, la Cámara del Consejo y el Cuadrángulo Sagrado con el Templo de la Reliquia Sagrada (Watadage), la Casa de la Imagen (Thuparama), el Hatadage, la Tabla de Inscripciones de Piedra (Galpotha) y el templo de siete pisos (Sath Mahal Prasadaya).</p><p>Otros sitios importantes en la ciudad antigua incluyen las capillas de imágenes de Lankathilaka y Thiwanka, los templos grandes y bien conservados de Rankoth Vehera y Kirivehera. , el templo de roca Gal Viharaya que contiene estatuas de Buda exquisitamente talladas, el complejo monástico y de biblioteca (Pothgul Viharaya) y el embalse del rey Parakrama (Parakrama Samudraya). Los viajeros de Guia Lanka Tours tienen la opción de explorar la antigua ciudad de Polonnaruwa en bicicleta.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fpolonnaruwa.jpg?alt=media&token=4e268aa0-b548-4262-9190-e2fd84ff4540"},
      ],
    },
    {
      title: "Cascadas de Ramboda",
      slug: "ramboda-waterfalls",
      description:
        "<p>Las cataratas de Amboda, ubicadas en las colinas centrales de Sri Lanka, son una impresionante cascada conocida por su pintoresca belleza. Cayendo en cascada desde una altura de más de 90 metros, ofrece un escape tranquilo en medio de una exuberante vegetación. Los visitantes pueden admirar las cataratas desde miradores a lo largo del camino o acercarse para experimentar su refrescante niebla.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Framboda.jpg?alt=media&token=11d5e1ad-15a0-4f99-a249-78c3bebb66cc"},
      ],
    },
    {
      title: "Cascadas de Ravana",
      slug: "ravana-waterfalls",
      description:
        "<p>Las cataratas Ravana, cerca de la ciudad de Ella en Sri Lanka, son una impresionante atracción natural con una altura de aproximadamente 25 metros. Según la leyenda local, está asociado con la epopeya hindú Ramayana. Ofrece un espectáculo visual impresionante y es una visita obligada para quienes exploran la región de Ella. Es un lugar perfecto para relajarse y disfrutar de la belleza de la naturaleza.</p>",
      slides: [
        {img:"https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fattractions%2Fravana.jpg?alt=media&token=8968d57d-8eca-411f-8ff1-1774463e77fc"},
      ],
    },
    
  ];
  