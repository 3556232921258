import React from "react";

import { excursionDetails } from "../../../../constants/excursionsConstant";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Container, Row, Col } from "react-bootstrap";
import ExcursionsSlider from "../../Excursions/components/ExcursionsSlider";
const ExcursionsSection = () => {
    var items = [];
  items = excursionDetails;


    return(<>
    <section className="mainSection excursionsSection">
        <Container fluid>
          <Row>
            
            <Col
              md={12}
              className="padding-side-50 padding-over-50 content-align-center"
            >
              <h2 className="h2 h2-main color-white text-center">Excursiones</h2>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col md={12} className="padding-over-50 masCont d-none d-sm-block">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry className="card2-grid">
                    {items.map((item, index) => {
                      return (
                        <>
                          <a className="card2" href={"/excursions/" + item.slug}>
                            <div
                              className="card2__background"
                              style={{
                                backgroundImage: `url(` + item.slides[0].img + `)`,
                              }}
                            ></div>
                            <div className="card2__content">
                              <p className="card2__category"></p>
                              <h3 className="card2__heading">{item.title}</h3>
                            </div>
                          </a>
                        </>
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </Col>
              <Col md={12} className="masCont d-block d-sm-none">
                    <ExcursionsSlider />
              </Col>
            </Row>
            
          </Container>
        </Container>
      </section>
    </>);
}

export default ExcursionsSection;