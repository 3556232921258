import React from "react";
import "./styles.css";
import { Container, Row, Col } from "react-bootstrap";
const AboutSection = () => {
  return (
    <>
      <section className="mainSection aboutSection" id="about">
        <Container className="bg-light-green2">
          <Row>
            <Col md={4} className='d-none d-lg-block'>
                <div className="aboutImageContainer padding-over-50 padding-left-50">
                    <img src={'https://firebasestorage.googleapis.com/v0/b/guiaweb-98128.appspot.com/o/images%2Fpages%2Fhome%2Fabout1.jpg?alt=media&token=3cda77f4-a4ca-4ae4-9641-3c0acbd57a8d'} alt="GET TO KNOW GUIA LANKA TOURS ABOUT US" />
                </div>
            </Col>
            <Col md={8} className="padding-side-50 padding-over-50 content-align-center">
              <h2 className="h2 h2-main text-left">
                {" "}
                <span className="small">Conozca Guia Lanka Tours</span>
                <br />
                Sobre nosotros
              </h2>
              <p>
              Una experiencia de viaje verdaderamente auténtica se basa en una conexión personal y humana con las personas o el lugar que visita. Un momento de sentida experiencia compartida. Cuando el viajero actúa de manera afectuosa, compasiva y responsable con la comunidad anfitriona, puede experimentar la sensación de asombro por las personas y los lugares visitados como una emoción positiva.
              </p>

            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutSection;
