import React from "react";
import { excursionDetails } from "../../../constants/excursionsConstant";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Container, Row, Col } from "react-bootstrap";
import ContactSection from "../HomePage/components/ContactSection";

const Excursions = () => {
  var items = [];
  items = excursionDetails;



  return (
    <>
      <section className="mainSection ">
        <Container fluid>
          <Row>
            <Col md={9} className="padding-side-0"></Col>
            <Col
              md={3}
              className="padding-side-50 padding-over-50 content-align-center"
            >
              <h1 className="h1 h1-main text-left ">Excursions</h1>
            </Col>
          </Row>
          <Container>
            <Row>
              <Col md={12} className="padding-over-50 masCont">
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
                >
                  <Masonry className="card2-grid">
                    {items.map((item, index) => {
                      return (
                        <>
                          <a className="card2" href={"/excursions/" + item.slug}>
                            <div
                              className="card2__background"
                              style={{
                                backgroundImage: `url(` + item.slides[0].img + `)`,
                              }}
                            ></div>
                            <div className="card2__content">
                              <p className="card2__category"></p>
                              <h3 className="card2__heading">{item.title}</h3>
                            </div>
                          </a>
                        </>
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </Col>
            </Row>
            
          </Container>
        </Container>
      </section>
      <ContactSection />
    </>
  );
};

export default Excursions;
