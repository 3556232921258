import "./App.css";
import Page from "./components/pages/Page";
import { BrowserRouter} from "react-router-dom";
import MainNav from "./components/common/MainNav/MainNav";
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <>
      <BrowserRouter>
        <MainNav />
        <Page />
      </BrowserRouter>
    </>
  );
}

export default App;
