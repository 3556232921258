import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { offerDetails } from "../../../constants/offerConstant";
import SingleOffer from "./components/SingleOffer";
import ConntactSection from '../HomePage/components/ContactSection';
const Offers = () => {
    const [singleTour, setSingleTour] = useState("");
  let { id } = useParams();
    var tourArr = useMemo(() => [], []);
    tourArr = offerDetails;
  useEffect(() => {
    tourArr.forEach(tour => {
        if(tour.slug === id){
            setSingleTour(tour);
        }
    });
  }, [id, tourArr]);
    return(
        <>
        
        <SingleOffer title={singleTour.title} thumbnail={singleTour.thumbnail} descriptions={singleTour.descriptions} summary={singleTour.summary} includes={singleTour.inclues} excludes={singleTour.excludes} special={singleTour.special}/>
        <ConntactSection />
        </>
    );
}

export default Offers;